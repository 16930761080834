import { Grid, styled, SwipeableDrawer } from "@mui/material";
import { greyDark, greyLighter } from "../../common/Theme/theme";

const MOBILE_NAVIGATION_PREFIX = "MobileNavigation";

export const mobileNavigationClasses = {
    root: `${MOBILE_NAVIGATION_PREFIX}-root`,
    menuButton: `${MOBILE_NAVIGATION_PREFIX}-menuButton`,
    navigation: `${MOBILE_NAVIGATION_PREFIX}-navigation`,
    pageLinkText: `${MOBILE_NAVIGATION_PREFIX}-pageLinkText`,
    box: `${MOBILE_NAVIGATION_PREFIX}-box`,
    logo: `${MOBILE_NAVIGATION_PREFIX}-logo`,
    container: `${MOBILE_NAVIGATION_PREFIX}-container`,
    link: `${MOBILE_NAVIGATION_PREFIX}-link`,
};

export const StyledMobileNavigation = styled(Grid)(({ theme }) => ({
    [`&.${mobileNavigationClasses.root}`]: {
        backgroundColor: greyDark,
        position: "fixed",
        zIndex: 100,
        width: "100%",
    },
    [`& .${mobileNavigationClasses.menuButton}`]: {
        color: theme.palette.common.white,
        marginLeft: "10px",
    },
}));

export const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
    [`& .${mobileNavigationClasses.logo}`]: {
        margin: "10px",
    },
    [`& .${mobileNavigationClasses.container}`]: {
        borderTop: "1px solid",
    },
    [`& .${mobileNavigationClasses.navigation}`]: {
        width: "120px",
        fontSize: 16,
        my: 2,
        color: theme.palette.common.white,
        display: "block",
        textTransform: "capitalize",
    },
    [`& .${mobileNavigationClasses.pageLinkText}`]: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "19px",
        color: "#272D3A",
    },
    [`& .${mobileNavigationClasses.box}`]: {
        display: "flex",
        flexDirection: "column",
    },
    [`& .${mobileNavigationClasses.link}`]: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "19px",
        textDecoration: "none",
        cursor: "pointer",
        color: "#272D3A",
        marginBottom: "10px",
    },
}));
