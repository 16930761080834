import { Grid, styled } from "@mui/material";
import { primary, greyDark, grey, greyLight, greyLighter } from "../../common/Theme/theme";

const FOOTER_PREFIX = "Footer";

export const footerClasses = {
    root: `${FOOTER_PREFIX}-root`,
    logo: `${FOOTER_PREFIX}-logo`,
    navigation: `${FOOTER_PREFIX}-navigation`,
    container: `${FOOTER_PREFIX}-container`,
    topContainer: `${FOOTER_PREFIX}-topContainer`,
    bottomContainer: `${FOOTER_PREFIX}-bottomContainer`,
    bottomContainerText: `${FOOTER_PREFIX}-bottomContainerText`,
    bottomContainerTextRight: `${FOOTER_PREFIX}-bottomContainerTextRight`,
    bottomWrapper: `${FOOTER_PREFIX}-bottomWrapper`,
    icon: `${FOOTER_PREFIX}-icon`,
    topContainerColumn: `${FOOTER_PREFIX}-topContainerColumn`,
    description: `${FOOTER_PREFIX}-description`,
    email: `${FOOTER_PREFIX}-email`,
    address: `${FOOTER_PREFIX}-address`,
    columnHeading: `${FOOTER_PREFIX}-columnHeading`,
    columnText: `${FOOTER_PREFIX}-columnText`,
    iconLink: `${FOOTER_PREFIX}-iconLink`,
};

export const StyledFooter = styled(Grid)(({ theme }) => ({
    width: "100%",
    [`&.${footerClasses.root}`]: {
        background: greyDark,
    },
    [`& .${footerClasses.container}`]: {
        display: "flex",
        flexDirection: "column",
    },
    [`& .${footerClasses.bottomWrapper}`]: {
        display: "flex",
        flexDirection: "column",
        background: greyDark,
        borderTop: "1px solid",
        borderColor: grey,
    },
    [`& .${footerClasses.topContainer}`]: {
        height: "auto",
        padding: "30px 0",
        display: "flex",
        flexDirection: "row",
    },
    [`& .${footerClasses.logo}`]: {
        fontFamily: "Oswald",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "24px",
        lineHeight: "24px",
        marginBottom: "40px",
    },
    [`& .${footerClasses.navigation}`]: {
        fontSize: 16,
        color: primary,
        marginBottom: "20px",
        fontWeight: "normal",
        cursor: "pointer",
    },
    [`& .${footerClasses.bottomContainerText}`]: {
        textAlign: "left",
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "20px",
        color: greyLight,
    },
    [`& .${footerClasses.bottomContainerTextRight}`]: {
        textAlign: "right",
        marginLeft: "10px",
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "20px",
        color: greyLight,
    },
    [`& .${footerClasses.bottomContainer}`]: {
        height: "70px",
        padding: "20px 0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    [`& .${footerClasses.icon}`]: {
        marginRight: "10px",
        marginBottom: "10px",
    },
    [`& .${footerClasses.topContainerColumn}`]: {
        display: "flex",
        width: "auto",
        flexWrap: "nowrap",
        color: "white",
        marginRight: "100px",
    },
    [`& .${footerClasses.description}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "14px",
        color: greyLight,
        marginBottom: "40px",
        width: "264px",
    },
    [`& .${footerClasses.email}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "14px",
        color: greyLight,
    },
    [`& .${footerClasses.address}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "14px",
        color: greyLight,
        marginBottom: "10px",
    },
    [`& .${footerClasses.columnHeading}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "16px",
        lineHeight: "19px",
    },
    [`& .${footerClasses.columnText}`]: {
        fontFamily: "Rubik",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "20x",
        color: greyLighter,
        textDecoration: "none",
        cursor: "pointer",
    },
    [`& .${footerClasses.iconLink}`]: {
        color: theme.palette.common.white,
    },
}));
