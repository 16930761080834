import { useContext, useEffect, useState } from "react";
import { estateDetailsClasses as classes, StyledEstateDetails } from "./StyledEstateDetails";
import { useParams } from "react-router-dom";
import { GlobalContext } from "../../App";
import { Container, Grid, Tab, Tabs } from "@mui/material";
import { ViewContainer } from "../ViewContainer/ViewContainer";
import { estateService } from "../../services/estate.service";
import { IEstate } from "../../interfaces/estate";
import { IAttachment } from "../../interfaces/attachment";
import { FeatureBoundary } from "../../common/FeatureBoundary/FeatureBoundary";
import { NavigationRoutes } from "../../routes";
import { useGA } from "../../common/hooks";
import { documentsEndpoint } from "../../endpoints";
import { buyerService } from "../../services/buyer.service";
import { documentKeys } from "../../services/estate-service.utils";
import { userService } from "../../services/user.service";
import { IProfile } from "../../interfaces/user";
import { paymentService } from "../../services/payment.service";
import { IPlan } from "../../interfaces/plan";
import { InvoiceModal } from "../../common/InvoiceModal/InvoiceModal";
import EstateDetailsDescription from "./EstateDetailsDescription/EstateDetailsDescription";
import EstateDetailsType from "./EstateDetailsType/EstateDetailsType";
import EstateDetailsOthers from "./EstateDetailsOthers/EstateDetailsOthers";
import EstateDetailsContacts from "./EstateDetailsContacts/EstateDetailsContacts";
import EstateDetailsMap from "./EstateDetailsMap/EstateDetailsMap";
import EstateDetailsDocuments from "./EstateDetailsDocuments.tsx/EstateDetailsDocuments";
import EstateDetailsDownloadDocumentsModal from "./EstateDetailsDownloadDocumentsModal/EstateDetailsDownloadDocumentsModal";
import EstateDetailsBuyCreditsModal from "./EstateDetailsBuyCreditsModal/EstateDetailsBuyCreditsModal";
import EstateDetailsUnlockEstateModal from "./EstateDetailsUnlockEstateModal/EstateDetailsUnlockEstateModal";
import EstateDetailsSuccessModal from "./EstateDetailsSuccessModal/EstateDetailsSuccessModal";
import EstateDetailsUpperContainerLeft from "./EstateDetailsUpperContainerLeft/EstateDetailsUpperContainerLeft";
import EstateDetailsUpperContainerRight from "./EstateDetailsUpperContainerRight/EstateDetailsUpperContainerRight";
import { getPlanDiscountText, getPlanRegularPriceText, getPlanTitle } from "./PlansPaper";
import EstateDetailsContactInfoModal from "./EstateDetailsContactInfoModal/EstateDetailsContactInfoModal";
import EstateDetailsUnlockContactModal from "./EstateDetailsUnlockContactModal/EstateDetailsUnlockContactModal";
import { Cost } from "../../common/utils";
import EstateDetailsUnlockEstateDiscountModal from "./EstateDetailsUnlockEstateDiscountModal/EstateDetailsUnlockEstateDiscountModal";

enum EstateTab {
    Description = "Description",
    Gallery = "Gallery",
}

export enum ModalNameParams {
    UnlockEstate = "unlock_estate",
    UnlockContact = "unlock_contact",
    UnlockEstateDiscount = "unlock_estate_discount",
    Error = "error",
    InvoiceEstate = "invoice-unlock-estate",
    InvoiceContact = "invoice-unlock-contact",
    InvoiceEstateDiscount = "invoice-unlock-estate-discount",
}

export const intentionKey = "intention";

const getDocumentFile = (action: string) => {
    let input = document.createElement("input");
    input.type = "hidden";
    input.name = "token";
    input.value = localStorage.getItem("token") || "";
    let form = document.createElement("form");
    form.action = action;
    form.method = "get";
    form.appendChild(input);
    document.body.appendChild(form);
    form.submit();
    form.remove();
};

const EstateDetails = () => {
    const { id, open_modal, order_id } = useParams();
    useGA();
    const [estate, setEstate] = useState<IEstate>(null as any);
    const { dropdownsMap } = useContext(GlobalContext);
    const [attachments, setAttachments] = useState<IAttachment[]>([]);
    const [currentTab, setCurrentTab] = useState(EstateTab.Description);
    const [openImageModal, setOpenImageModal] = useState(false);
    const { user } = useContext(GlobalContext);
    const [userProfile, setUserProfile] = useState<IProfile>();
    const [showDownloadDocumentsButton, setShowDownloadDocumentsButton] = useState(false);
    const [openDownloadDocumentsModal, setOpenDownloadDocumentsModal] = useState(false);
    const [openContactPreviewModal, setOpenContactPreviewModal] = useState(false);
    const [openUnlockContactModal, setOpenUnlockContactModal] = useState(false);
    const [openUnlockEstateDiscountModal, setOpenUnlockEstateDiscountModal] = useState(false);
    const [openBuyCreditsModal, setOpenBuyCreditsModal] = useState(false);
    const [openUnlockEstateModal, setOpenUnlockEstateModal] = useState<boolean>(false);
    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [hasDocumentObjects, setHasDocumentObjects] = useState(false);
    const [plans, setPlans] = useState<IPlan[]>([]);
    const [paymentProperties, setPaymentProperties] = useState([]);
    const [disableUnlockButton, setDisableUnlockButton] = useState(false);
    const [purchasePlanId, setPurchasePlanId] = useState<number | null>(null);
    const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
    const [order, setOrder] = useState();
    const [isOpened, setIsOpened] = useState(false);
    const [isGuest, setIsGuest] = useState(false);

    const checkForUploadedDocuments = (estate: IEstate) => {
        const availableDocument = documentKeys.some((documentKeyGroup) => {
            return !!estate[documentKeyGroup.idKey];
        });
        setShowDownloadDocumentsButton(availableDocument);
    };
    const checkForDocumentObjects = (estate: IEstate) => {
        const availableDocument = documentKeys.some((documentKeyGroup) => {
            return !!estate[documentKeyGroup.objectKey];
        });
        setHasDocumentObjects(availableDocument);
    };
    const fetchEstate = () => {
        if (user) {
            if (id) {
                estateService.getId(id!).then(({ estate, attachments }) => {
                    setEstate(estate);
                    setAttachments(attachments);
                    checkForUploadedDocuments(estate);
                    checkForDocumentObjects(estate);
                    setIsGuest(false);
                });
            }
        } else {
            if (id) {
                estateService.getIdAsGuest(id!).then(({ estate, attachments }) => {
                    setEstate(estate);
                    setAttachments(attachments);
                    checkForUploadedDocuments(estate);
                    checkForDocumentObjects(estate);
                    setIsGuest(true);
                });
            }
        }
    };

    const fetchUserProfile = () => {
        if (user) {
            userService.getById(user.id).then((userProfile) => {
                setUserProfile(userProfile);
            });
        }
    };

    const fetchPlans = () => {
        paymentService.getAllPlans().then((result) => {
            setPlans(result);
        });
    };

    useEffect(() => {
        fetchEstate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, user]);

    useEffect(() => {
        fetchUserProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        fetchPlans();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (open_modal === ModalNameParams.UnlockEstate) {
            setOpenUnlockEstateModal(true);
        }
        if (open_modal === ModalNameParams.UnlockContact) {
            setOpenUnlockContactModal(true);
        }
        if (open_modal === ModalNameParams.UnlockEstateDiscount) {
            setOpenUnlockEstateDiscountModal(true);
        }
        if (open_modal === ModalNameParams.Error) {
            setOpenErrorModal(true);
        }
        if (
            open_modal === ModalNameParams.InvoiceEstate ||
            open_modal === ModalNameParams.InvoiceContact ||
            open_modal === ModalNameParams.InvoiceEstateDiscount
        ) {
            setOpenInvoiceModal(true);
        }
    }, [open_modal]);

    useEffect(() => {
        if (order_id) {
            paymentService.getOrder(parseInt(order_id)).then((order) => {
                setOrder(order);
            });
        }
    }, [order_id]);

    useEffect(() => {
        if (!!purchasePlanId && paymentProperties.length > 0) {
            (document.getElementById(`payment-plan-${purchasePlanId}`) as any)?.submit();
        }
    }, [purchasePlanId, paymentProperties]);

    const toggleFavourite = async (event: any) => {
        event.stopPropagation();
        if (estate.favourite) {
            await buyerService.makeUnFavourite(estate.id);
        } else {
            await buyerService.makeFavourite(estate.id);
        }
        fetchEstate();
    };

    const getOtherDocumentLabel = () => {
        return estate.other_documents_attachment_description.length > 0
            ? `Друг документ: ${estate.other_documents_attachment_description}`
            : "Друг документ";
    };

    const handleDownloadButtonClick = () => {
        if (user) {
            if (hasDocumentObjects) {
                getDocumentFile(`${documentsEndpoint}/all/${estate.id}}`);
            } else if (estate.email && estate.phone) {
                if (userProfile && userProfile?.info.credit_count >= Cost.DocumentMinusContact) {
                    setOpenUnlockEstateDiscountModal(true);
                } else {
                    sessionStorage.setItem(intentionKey, ModalNameParams.UnlockEstateDiscount);
                    setOpenBuyCreditsModal(true);
                }
            } else if (userProfile && userProfile?.info.credit_count >= Cost.Document) {
                setOpenUnlockEstateModal(true);
                // TODO: Add check if use has contacts paid
            } else {
                sessionStorage.setItem(intentionKey, ModalNameParams.UnlockEstateDiscount);
                setOpenBuyCreditsModal(true);
            }
        } else {
            setIsOpened(true);
        }
    };

    const handleContactUnlocked = () => {
        if (userProfile && userProfile?.info.credit_count >= Cost.Contact) {
            setOpenUnlockContactModal(true);
        } else {
            sessionStorage.setItem(intentionKey, ModalNameParams.UnlockContact);
            setOpenBuyCreditsModal(true);
        }
    };

    return (
        <ViewContainer>
            <StyledEstateDetails container className={classes.root}>
                <Grid container className={classes.upperContainer}>
                    <EstateDetailsUpperContainerLeft
                        user={user}
                        estate={estate}
                        dropdownsMap={dropdownsMap}
                        showDownloadDocumentsButton={showDownloadDocumentsButton}
                        toggleFavourite={toggleFavourite}
                        getOtherDocumentLabel={getOtherDocumentLabel}
                        setOpenDownloadDocumentsModal={setOpenDownloadDocumentsModal}
                        isOpened={isOpened}
                        setIsOpened={setIsOpened}
                    />
                    <EstateDetailsUpperContainerRight
                        estate={estate}
                        openImageModal={openImageModal}
                        attachments={attachments}
                        dropdownsMap={dropdownsMap}
                        setOpenImageModal={setOpenImageModal}
                    />
                </Grid>
                <Container fixed>
                    <Grid container>
                        <Tabs
                            value={currentTab}
                            onChange={(_event, value) => {
                                setCurrentTab(value);
                            }}>
                            <Tab value={EstateTab.Description} label="Описание" />
                        </Tabs>
                    </Grid>
                    {currentTab === EstateTab.Description && estate && dropdownsMap && (
                        <Grid container marginTop="20px">
                            <Grid item xs={12} sm={12} md={8} lg={8}>
                                <EstateDetailsDescription estate={estate} dropdownsMap={dropdownsMap} />
                                <EstateDetailsType estate={estate} dropdownsMap={dropdownsMap} />
                                <EstateDetailsOthers estate={estate} dropdownsMap={dropdownsMap} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <EstateDetailsContacts
                                    estate={estate}
                                    setOpenContactPreviewModal={setOpenContactPreviewModal}
                                    isGuest={isGuest}
                                    isOpened={isOpened}
                                    setIsOpened={setIsOpened}
                                />
                                <EstateDetailsMap estate={estate} dropdownsMap={dropdownsMap} />
                                <EstateDetailsDocuments
                                    user={user}
                                    estate={estate}
                                    showDownloadDocumentsButton={showDownloadDocumentsButton}
                                    setOpenDownloadDocumentsModal={setOpenDownloadDocumentsModal}
                                    getOtherDocumentLabel={getOtherDocumentLabel}
                                    isOpened={isOpened}
                                    setIsOpened={setIsOpened}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Container>
                <EstateDetailsDownloadDocumentsModal
                    estate={estate}
                    dropdownsMap={dropdownsMap}
                    openDownloadDocumentsModal={openDownloadDocumentsModal}
                    setOpenDownloadDocumentsModal={setOpenDownloadDocumentsModal}
                    handleDownloadButtonClick={handleDownloadButtonClick}
                    getOtherDocumentLabel={getOtherDocumentLabel}
                />
                <EstateDetailsContactInfoModal
                    estate={estate}
                    dropdownsMap={dropdownsMap}
                    openContactPreviewModal={openContactPreviewModal}
                    setOpenContactPreviewModal={setOpenContactPreviewModal}
                    handleContactUnlocked={handleContactUnlocked}
                />
                <EstateDetailsBuyCreditsModal
                    id={id}
                    plans={plans}
                    purchasePlanId={purchasePlanId}
                    paymentProperties={paymentProperties}
                    user={user}
                    openBuyCreditsModal={openBuyCreditsModal}
                    setOpenBuyCreditsModal={setOpenBuyCreditsModal}
                    getPlanTitle={getPlanTitle}
                    getPlanRegularPriceText={getPlanRegularPriceText}
                    getPlanDiscountText={getPlanDiscountText}
                    setPurchasePlanId={setPurchasePlanId}
                    setPaymentProperties={setPaymentProperties}
                />
                <EstateDetailsUnlockEstateModal
                    estate={estate}
                    dropdownsMap={dropdownsMap}
                    openUnlockEstateModal={openUnlockEstateModal}
                    user={user}
                    setOpenUnlockEstateModal={setOpenUnlockEstateModal}
                    setOpenSuccessModal={setOpenSuccessModal}
                    setDisableUnlockButton={setDisableUnlockButton}
                    disableUnlockButton={disableUnlockButton}
                />
                <EstateDetailsUnlockContactModal
                    estate={estate}
                    dropdownsMap={dropdownsMap}
                    openUnlockContactModal={openUnlockContactModal}
                    user={user}
                    setOpenUnlockContactModal={setOpenUnlockContactModal}
                    setOpenSuccessModal={setOpenSuccessModal}
                    setDisableUnlockButton={setDisableUnlockButton}
                    disableUnlockButton={disableUnlockButton}
                />
                <EstateDetailsUnlockEstateDiscountModal
                    estate={estate}
                    dropdownsMap={dropdownsMap}
                    openUnlockEstateDiscountModal={openUnlockEstateDiscountModal}
                    user={user}
                    setOpenUnlockEstateDiscountModal={setOpenUnlockEstateDiscountModal}
                    setOpenSuccessModal={setOpenSuccessModal}
                    setDisableUnlockButton={setDisableUnlockButton}
                    disableUnlockButton={disableUnlockButton}
                />
                <EstateDetailsSuccessModal
                    estate={estate}
                    openSuccessModal={openSuccessModal}
                    openErrorModal={openErrorModal}
                    setOpenSuccessModal={setOpenSuccessModal}
                    setOpenErrorModal={setOpenErrorModal}
                />
                <InvoiceModal
                    order={order}
                    open={openInvoiceModal}
                    setOpen={setOpenInvoiceModal}
                    email={userProfile?.info.email}
                    onClose={() => {
                        if (open_modal === ModalNameParams.InvoiceEstate) {
                            setOpenUnlockEstateModal(true);
                        } else if (open_modal === ModalNameParams.InvoiceEstateDiscount) {
                            setOpenUnlockEstateDiscountModal(true);
                        } else if (open_modal === ModalNameParams.InvoiceContact) {
                            setOpenUnlockContactModal(true);
                        }
                    }}
                />
            </StyledEstateDetails>
        </ViewContainer>
    );
};

export default EstateDetails;
